<template>
  <div>
    <a-breadcrumb>
      <a-breadcrumb-item
        v-for="department in departmentBreadcrumb"
        :key="department.id"
        href=""
        @click.native="handleDepartmentClick(department)"
      >
        {{ department.name }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <template v-if="currentDepartmentList.length">
      <a-divider />
      <a-radio-group v-if="showSelect" v-model="modelValue" @change="handleChange">
        <div
          class="department-item"
          v-for="department in currentDepartmentList"
          :key="department.id"
        >
          <a-radio class="department-item--name" :value="department.id">
            {{ department.name }}
          </a-radio>
          <a-button type="link" icon="cluster" @click="handleDepartmentClick(department)">
            下级
          </a-button>
        </div>
      </a-radio-group>
      <template v-else>
        <div
          class="department-item"
          v-for="department in currentDepartmentList"
          :key="department.id"
        >
          <div class="department-item--name">{{ department.name }}</div>
          <a-button type="link" icon="cluster" @click="handleDepartmentClick(department)">
            下级
          </a-button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { findNode, findPath } from '@ys/pro-utils/lib/tree'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: undefined,
    },
    departmentTree: {
      type: Array,
      default: () => [],
    },
    currentDepartment: {
      type: Object,
      default: undefined,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      breadcrumb: [],
    }
  },
  computed: {
    ...mapGetters(['roleList']),
    modelValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    currentDepartmentList() {
      if (!this.currentDepartmentModel) {
        return this.departmentTree ?? []
      }
      return this.currentDepartmentModel?.children ?? []
    },
    departmentBreadcrumb() {
      return findPath(this.departmentTree, (node) => node.id === this.currentDepartmentModel.id)
    },
    currentDepartmentModel: {
      get() {
        return this.currentDepartment
      },
      set(value) {
        this.$emit('update:current-department', value)
      },
    },
  },
  mounted() {},
  methods: {
    handleDepartmentClick(department) {
      this.currentDepartmentModel = department
    },
    handleChange(e) {
      this.$emit('change', {
        value: e.target.value,
        option: findNode(this.departmentTree, (node) => {
          return node.id === e.target.value
        }),
      })
    },
  },
}
</script>

<style scoped>
.ant-radio-group {
  width: 100%;
}

.department-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.department-item .department-item--name {
  flex: 1;
}
</style>

import InfiniteSelect from './components/infiniteSelect'
import ListSort from './components/listSort'
import Provider from './components/provider'
import SearchForm from './components/searchForm'
import Select from './components/select'
import Table from './components/table'
import TableLayout from './components/tableLayout'

const components = { Provider, Table, ListSort, TableLayout, SearchForm, InfiniteSelect, Select }

export default {
  ...components,
  install(Vue) {
    Object.values(components).forEach((component) => {
      Vue.component(component.name, component)
    })
  },
}
